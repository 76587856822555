import React, { useState } from "react";
import ethsideGameImg from "../images/ethside-game-img.jpg";

function Slider() {
  const [value, setValue] = useState(0);

  return (
    <div className="con">
      <div className="slider">
        <div className={value == 0 ? "item active" : "item"}>
          <div className="ethside-g-img">
            <img src={ethsideGameImg} alt="" />
          </div>
        </div>

        <div className={value == 1 ? "item active" : "item"}>
          <div className="ethside-g-img">
            <img src={ethsideGameImg} alt="" />
          </div>
        </div>

        <div className={value == 2 ? "item active" : "item"}>
          <div className="ethside-g-img">
            <img src={ethsideGameImg} alt="" />
          </div>
        </div>
      </div>

      <button
        onClick={() => {
          value === 0 ? setValue(2) : setValue(value - 1);
        }}
        className="back-btn"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <button
        onClick={() => {
          value === 2 ? setValue(0) : setValue(value + 1);
        }}
        className="next-btn"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  );
}

export default Slider;
