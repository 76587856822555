import "./css/bootstrap.css";
import "./css/owl.carousel.css";
import "./css/owl.theme.default.css";
import "./css/style.css";
import "./css/responsive.css";
import "@brainhubeu/react-carousel/lib/style.css";
import "./css/owl.carousel.css";
import React, { useEffect, useState } from "react";

import logo from "./images/logo.png";
import banner from "./images/banner.jpg";
import twitter from "./images/twitter.svg";
import medium from "./images/design-m.svg";
import discord from "./images/discord.svg";
import character from "./images/character.gif";
import futureLogo from "./images/future-logo.png";
import Slider from "./components/Slider";

function App() {
  const [mobile, setmobile] = useState(false);
  const [value, setValue] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <div className="App">
      {/* <!--- Header Section Start Here ----> */}
      <div className={scrollTop ? "header fixed" : "header"}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <a className="logo" href="index.html">
                <img src={logo} alt="#" />
              </a>
              <button
                className={mobile ? "mobile-menu change" : "mobile-menu"}
                onClick={() => setmobile(!mobile)}
              >
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </button>
              <div
                className={mobile ? "navigation menu_show" : "navigation"}
                id="mySidenav"
              >
                <ul className="nav-sub-menu left">
                  <li>
                    <a
                      href="#banner-section"
                      onClick={() => setmobile(!mobile)}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="#utc-section" onClick={() => setmobile(!mobile)}>
                      Mint
                    </a>
                  </li>
                  <li>
                    <a href="#mafia-section" onClick={() => setmobile(!mobile)}>
                      About
                    </a>
                  </li>
                </ul>
                <ul className="nav-sub-menu right">
                  <li>
                    <a href="#game-section" onClick={() => setmobile(!mobile)}>
                      Ethside Games
                    </a>
                  </li>
                  <li>
                    <a href="#road-section" onClick={() => setmobile(!mobile)}>
                      Roadmap
                    </a>
                  </li>
                  <li>
                    <a
                      href="#future-section"
                      onClick={() => setmobile(!mobile)}
                    >
                      Future
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--- Header Section End ----> */}

      {/* <!--- Banner Section Start Here ----> */}
      <div className="banner" id="banner-section">
        <img className="desktop-banner" src={banner} alt="" />
        <div className="banner-txt-main">
          <div className="banner-txt-main-b">
            <div className="banner-txt-main-c">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--- Banner Section End ----> */}

      {/* <!--- UTC Section Start Here ----> */}
      <div className="utc-sec" id="utc-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="utc-bg-main">
                <div className="utc-info">
                  <h1>December 2nd UTC Time</h1>
                  <span>7600 left/8888 / 0.0888 ETH per avatar</span>
                  <button className="utc-mint-btn" type="button">
                    Mint
                  </button>

                  <div className="social-nft">
                    <ul>
                      <li>
                        <a
                          href="https://twitter.com/EthsideMafia"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={twitter} alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://medium.com/@ethsidemafia"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={medium} alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://discord.com/invite/ethsidemafia"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={discord} alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h6>
                    In the year 2150 when the world runs on Ethereum. There is
                    just one family you <br />
                    want to be a part of...
                  </h6>

                  <div className="utc-detail">
                    <ul>
                      <li>This is just the beginning.</li>
                      <li>We are a movement.</li>
                      <li>We are decentralized.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--- UTC Section End ----> */}

      {/* <!--- Ethside Mafia Section Start Here ----> */}
      <div className="ethside-mafia-sec" id="mafia-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="ethside-mafia-img">
                <img src={character} alt="" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="ethside-mafia-info">
                <h1>We are the Ethside Mafia</h1>
                <p>
                  Ethside Mafia is a collection consisting of 8888
                  programmatically generated avatars from 190 traits from 10
                  categories.
                </p>

                <p>
                  This ragtag family has only two goals. To expand and provide
                  value back to the family.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--- Ethside Mafia Section End ----> */}

      {/* <!--- Ethside Games Section Start Here ----> */}
      <div className="ethside-game-sec" id="game-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title">
                <h1>Ethside Games</h1>
                <p>
                  We believe that Play2Earn is the future and EthsideGames.club
                  is your #1 spot to earn NFTs. Our model gives our family free
                  credits when they sign up and you can withdraw Ethside Credits
                  (ESC) to the Ethereum network to spend in our Community Prize
                  Pool.
                </p>

                <p>
                  If you see something that you really desire, you can deposit
                  more than 20+ cryptocurrencies to help the process. 100% of
                  the deposited funds will be split between the Community Prize
                  Wallet and the Community Vault.
                </p>

                <p>
                  Ethside Games is a new style of NFT marketplace that is 100%
                  community owned.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--- Ethside Games Section End ----> */}
      {/* <!--- Ethside Section Start Here ----> */}
      <div className="eth-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="ethside-game-slider">
                <Slider />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--- Ethside Section End ----> */}

      {/* <!--- Road Map Section Start Here --> */}
      <div className="road-map-sec" id="road-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="title">
                <h1>Roadmap</h1>
                <p>
                  At the following milestones, these events will be put into
                  action. <br />
                  Amount Sold:
                </p>
              </div>

              <section id="cd-timeline" className="cd-container">
                <div className="tp-circle"></div>

                <div className="cd-timeline-block">
                  <div
                    className="cd-timeline-img"
                    style={{ background: "#fff" }}
                  >
                    <h6>10%</h6>
                  </div>
                  <div className="cd-timeline-content wow fadeInUp">
                    <p>Start next business release</p>
                  </div>
                </div>

                <div className="cd-timeline-block">
                  <div
                    className="cd-timeline-img"
                    style={{ background: "#fff" }}
                  >
                    <h6>20%</h6>
                  </div>
                  <div className="cd-timeline-content">
                    <p>Send 15eth to community wallet for prizes</p>
                  </div>
                </div>

                <div className="cd-timeline-block">
                  <div
                    className="cd-timeline-img"
                    style={{ background: "#fff" }}
                  >
                    <h6>30%</h6>
                  </div>
                  <div className="cd-timeline-content">
                    <p>Start next drop for community</p>
                  </div>
                </div>

                <div className="cd-timeline-block">
                  <div
                    className="cd-timeline-img"
                    style={{ background: "#fff" }}
                  >
                    <h6>40%</h6>
                  </div>
                  <div className="cd-timeline-content">
                    <p>Send 10eth to community vault</p>
                  </div>
                </div>

                <div className="cd-timeline-block">
                  <div
                    className="cd-timeline-img"
                    style={{ background: "#fff" }}
                  >
                    <h6>50%</h6>
                  </div>
                  <div className="cd-timeline-content wow fadeInUp">
                    <p>Reveal next business release</p>
                  </div>
                </div>

                <div className="cd-timeline-block">
                  <div
                    className="cd-timeline-img"
                    style={{ background: "#fff" }}
                  >
                    <h6>60%</h6>
                  </div>
                  <div className="cd-timeline-content">
                    <p>Send 25eth to community wallet for prizes</p>
                  </div>
                </div>

                <div className="cd-timeline-block">
                  <div
                    className="cd-timeline-img"
                    style={{ background: "#fff" }}
                  >
                    <h6>70%</h6>
                  </div>
                  <div className="cd-timeline-content">
                    <p>Reveal February's business release</p>
                  </div>
                </div>

                <div className="cd-timeline-block">
                  <div
                    className="cd-timeline-img"
                    style={{ background: "#fff" }}
                  >
                    <h6>80%</h6>
                  </div>
                  <div className="cd-timeline-content">
                    <p>Finalize major deal</p>
                  </div>
                </div>

                <div className="cd-timeline-block">
                  <div
                    className="cd-timeline-img"
                    style={{ background: "#fff" }}
                  >
                    <h6>90%</h6>
                  </div>
                  <div className="cd-timeline-content">
                    <p>Send 15eth to community vault</p>
                  </div>
                </div>

                <div className="cd-timeline-block">
                  <div
                    className="cd-timeline-img"
                    style={{ background: "#fff" }}
                  >
                    <h6>100%</h6>
                  </div>
                  <div className="cd-timeline-content">
                    <p>Start building for the metaverse</p>
                  </div>
                </div>

                <div className="btm-circle"></div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* <!--- Road Map Section End --> */}

      {/* <!--- future Section Start Here ----> */}
      <div className="future-sec" id="future-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-last">
              <div className="future-img">
                <img src={futureLogo} alt="" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="ethside-mafia-info">
                <h1>The Future</h1>
                <p>
                  We plan to deliver smoothly and often providing real crypto
                  products that give back to their family. We live in a future
                  where jpegs and digital assets can work for you.
                </p>

                <p>
                  Everyone has their place in the family from the starving
                  artist to the greedy capitalist. Expect monthly releases.
                </p>

                <p>
                  Each user has ip over their avatars. With that we are
                  providing the family with their own logo to put on their
                  business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--- future Section End ----> */}

      {/* <!--- Footer Section Start Here ----> */}
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="social-nft">
                <ul>
                  <li>
                    <a
                      href="https://twitter.com/EthsideMafia"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={twitter} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://medium.com/@ethsidemafia"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={medium} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://discord.com/invite/ethsidemafia"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={discord} alt="" />
                    </a>
                  </li>
                </ul>
              </div>

              <ul className="f-menu">
                <li>
                  <a href="#banner-section">Home</a>
                </li>
                <li>
                  <a href="#utc-section">Mint</a>
                </li>
                <li>
                  <a href="#mafia-section">About</a>
                </li>
                <li>
                  <a href="#game-section">Ethside Games</a>
                </li>
                <li>
                  <a href="#road-section">Roadmap</a>
                </li>
                <li>
                  <a href="#future-section">Future</a>
                </li>
              </ul>

              <p>&copy; 2021 Ethside Games . All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
      {/* <!--- Footer Section End ----> */}
    </div>
  );
}

export default App;
